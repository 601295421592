<template>
    <div class="container" v-if="type">
        <div class="head">
            <div v-if="type === '10'">
                <span v-if="id">编辑图文</span>
                <span v-else>新建图文</span>
            </div>
            <div v-else-if="type === '20'">
                <span v-if="id">编辑音频</span>
                <span v-else>新建音频</span>
            </div>
            <div v-else-if="type === '30'">
                <span v-if="id">编辑视频</span>
                <span v-else>新建视频</span>
            </div>
            <div v-else-if="type === '40'">
                <span v-if="id">编辑专栏</span>
                <span v-else>新建专栏</span>
            </div>
            <div class="btn-area">
                <el-button @click="goBack">取消</el-button>
                <el-button type="primary" @click="handleSave">保存</el-button>
            </div>
        </div>
        <div class="form">
            <!-- 新建图文 -->
            <template v-if="type === '10'">
                <div class="form-title">基本信息</div>
                <div class="form-item">
                    <div class="form-item-name">图文名称</div>
                    <div class="form-item-content box-60">
                        <el-input v-model="form.name" placeholder="请输入图文名称(建议字数在14字以内，不超过45字)"></el-input>
                    </div>
                </div>
                <div class="form-item column">
                    <div class="form-item-name">图文封面</div>
                    <div class="form-item-content my-upload">
                        <el-upload class="upload-demo" :action="uploadUrl" :show-file-list="false"
                            :before-upload="beforeAvatarUpload" :http-request="uploadFile">
                            <div class="row">
                                <div>
                                    <img v-if="imageUrl" :src="imgApi + imageUrl" class="avatar" />
                                    <img v-else src="@/assets/images/default.png" alt="" class="size">
                                </div>
                                <div class="flex-start">
                                    <div class="row">
                                        <el-button size="small" class="width">点击上传</el-button>
                                        <div>上传进度 {{ progress }}%</div>
                                    </div>
                                    <div slot="tip" class="el-upload__tip">
                                        建议尺寸750px * 560px 或4:3，JPG、PNG格式，图片小于5M
                                    </div>
                                </div>
                            </div>
                        </el-upload>
                    </div>
                </div>
                <div class="form-item column">
                    <div class="form-item-name">图文详情</div>
                    <div class="form-item-content">
                        <div id="wang-editor"></div>
                    </div>
                </div>
                <div class="form-item column">
                    <div class="form-item-name">试看内容</div>
                    <div class="form-item-content">
                        <div id="wang-editor1"></div>
                    </div>
                </div>
            </template>
            <!-- 新建音频 -->
            <template v-else-if="type === '20'">
                <div class="form-title">基本信息</div>
                <div class="form-item">
                    <div class="form-item-name">音频名称</div>
                    <div class="form-item-content box-60">
                        <el-input v-model="form.name" placeholder="请输入音频名称(建议字数在14字以内，不超过45字)"></el-input>
                    </div>
                </div>
                <div class="form-item column">
                    <div class="form-item-name">音频上传</div>
                    <div class="form-item-content my-upload">
                        <el-upload class="upload-demo" ref="upload" :action="uploadUrl" :accept="audio" :limit="1"
                            :on-exceed="handleExceed" :on-remove="handleRemove" :file-list="fileList"
                            :http-request="uploadVideoFile">
                            <div class="row-progress">
                                <el-button size="small" type="primary" style="margin-right: 20px;">点击上传</el-button>
                                <div>上传进度 {{ progressVideo }}%</div>
                            </div>
                            <div slot="tip" class="el-upload__tip">
                                格式支持mp3、m4a，为保证音频加载与播放的流畅性，建议上传大小不超过500M
                            </div>
                        </el-upload>
                    </div>
                </div>
                <div class="form-item column">
                    <div class="form-item-name">音频封面</div>
                    <div class="form-item-content my-upload">
                        <el-upload class="upload-demo" :action="uploadUrl" :show-file-list="false"
                            :before-upload="beforeAvatarUpload" :http-request="uploadFile">
                            <div class="row">
                                <div>
                                    <img v-if="imageUrl" :src="imgApi + imageUrl" class="avatar" />
                                    <img v-else src="@/assets/images/default.png" alt="" class="size">
                                </div>
                                <div class="flex-start">
                                    <div class="row">
                                        <el-button size="small" class="width">点击上传</el-button>
                                        <div>上传进度 {{ progress }}%</div>
                                    </div>
                                    <div slot="tip" class="el-upload__tip">
                                        建议尺寸750px * 560px 或4:3，JPG、PNG格式，图片小于5M
                                    </div>
                                </div>
                            </div>
                        </el-upload>
                    </div>
                </div>
                <div class="form-item column">
                    <div class="form-item-name">音频详情</div>
                    <div class="form-item-content">
                        <div id="wang-editor"></div>
                    </div>
                </div>
                <div class="form-item column">
                    <div class="form-item-name top-4">片段试听</div>
                    <div class="form-item-content">
                        <div class="row">
                            <el-checkbox v-model="form.open" @change="openTryIt">开启片段试听</el-checkbox>
                            <div class="tip">
                                为用户提供一段时间的试听内容，刺激用户购买
                            </div>
                        </div>
                        <div v-if="tryIt" class="listen">
                            <div class="listen-set">试听设置</div>
                            <div class="listen-area">
                                <span class="front">音频前</span>
                                <el-input v-model.number="form.preview_time.time" placeholder="0" class="time">
                                </el-input>
                                <span class="minute">分钟为试听范围</span>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <!-- 新建视频 -->
            <template v-else-if="type === '30'">
                <div class="form-title">基本信息</div>
                <div class="form-item">
                    <div class="form-item-name">视频名称</div>
                    <div class="form-item-content box-60">
                        <el-input v-model="form.name" placeholder="请输入视频名称(建议数字在14字以内，不超过45字)"></el-input>
                    </div>
                </div>
                <div class="form-item column">
                    <div class="form-item-name">视频上传</div>
                    <div class="form-item-content my-upload">
                        <el-upload class="upload-demo" :action="uploadUrl" :before-remove="beforeRemove" :accept="video"
                            :limit="1" :on-exceed="handleExceed" :file-list="fileList" :http-request="uploadVideoFile">
                            <div class="row-progress">
                                <el-button size="small" type="primary" style="margin-right: 20px;">点击上传</el-button>
                                <div>上传进度 {{ progressVideo }}%</div>
                            </div>
                            <div slot="tip" class="el-upload__tip">
                                支持mp4，avi，wmv，mov，flv，rmvb，3gp，m4v，mkv格式；文件最大不超过5G。
                            </div>
                        </el-upload>
                    </div>
                </div>
                <div class="form-item column">
                    <div class="form-item-name">视频封面</div>
                    <div class="form-item-content my-upload">
                        <el-upload class="upload-demo" :action="uploadUrl" :show-file-list="false"
                            :before-upload="beforeAvatarUpload" :http-request="uploadFile">
                            <div class="row">
                                <div>
                                    <img v-if="imageUrl" :src="imgApi + imageUrl" class="avatar" />
                                    <img v-else src="@/assets/images/default.png" alt="" class="size">
                                </div>
                                <div class="flex-start">
                                    <div class="row">
                                        <el-button size="small" class="width">点击上传</el-button>
                                        <div>上传进度 {{ progress }}%</div>
                                    </div>
                                    <div slot="tip" class="el-upload__tip">
                                        建议尺寸750px * 560px 或4:3，JPG、PNG格式，图片小于5M
                                    </div>
                                </div>
                            </div>
                        </el-upload>
                    </div>
                </div>
                <div class="form-item column">
                    <div class="form-item-name">视频详情</div>
                    <div class="form-item-content">
                        <div id="wang-editor"></div>
                    </div>
                </div>
                <div class="form-item column">
                    <div class="form-item-name">文字防复制</div>
                    <div class="form-item-content column">
                        <el-radio v-model="form.text_copy_limit" :label="1">允许复制</el-radio>
                        <div class="tips">
                            商品的文字内容允许复制，图片点击放大和长按识别二维码功能允许使用
                        </div>
                        <el-radio v-model="form.text_copy_limit" :label="2">禁止复制</el-radio>
                        <div class="tips">
                            商品的文字内容禁止复制，图片点击放大和长按识别二维码功能不允许使用
                        </div>
                    </div>
                </div>
                <div class="form-item column">
                    <div class="form-item-name top-4">片段试看</div>
                    <div class="form-item-content">
                        <div class="row">
                            <el-checkbox v-model="form.open" @change="openTryIt">开启片段试看</el-checkbox>
                            <div class="tip">
                                为用户提供一段时间的试看内容，刺激用户购买
                            </div>
                        </div>
                        <div v-if="tryIt" class="listen">
                            <div class="listen-set">试看设置</div>
                            <div class="listen-area">
                                <span class="front">视频前</span>
                                <el-input v-model.number="form.preview_time.time" placeholder="0" class="time">
                                </el-input>
                                <span class="minute">分钟为试看范围</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-title">防录屏跑马灯</div>
                <div class="form-item">
                    <div class="form-item-name">防录屏跑马灯</div>
                    <div class="form-item-content">
                        <el-radio v-model="form.run" :label="1">开启</el-radio>
                        <el-radio v-model="form.run" :label="0">关闭</el-radio>
                    </div>

                </div>
                <div class="form-item mt-0" v-if="form.run === 1">
                    <div class="form-item-name"></div>
                    <div class="watermark">
                        <div class="open">开启后，学员观看课程会出现个人ID信息，可有效防止录屏。</div>
                        <screen-recording-watermark class="add-field" @submit='handleCourseSubmit' :opacityConfig='form.opacityConfig'>
                        </screen-recording-watermark>
                    </div>

                </div>

            </template>
            <!-- 新建专栏 -->
            <template v-else-if="type === '40'">
                <div class="form-title">基本信息</div>
                <div class="form-item">
                    <div class="form-item-name">专栏名称</div>
                    <div class="form-item-content box-60">
                        <el-input v-model="form.name" placeholder="请输入专栏名称(建议数字在14字以内，不超过45字)"></el-input>
                    </div>
                </div>
                <div class="form-item">
                    <div class="form-item-name">专栏简介</div>
                    <div class="form-item-content box-60">
                        <el-input type="textarea" v-model="form.info" placeholder="请输入专栏简介"></el-input>
                    </div>
                </div>
                <div class="form-item column">
                    <div class="form-item-name">专栏封面</div>
                    <div class="form-item-content my-upload">
                        <el-upload class="upload-demo" :action="uploadUrl" :show-file-list="false"
                            :before-upload="beforeAvatarUpload" :http-request="uploadFile">
                            <div class="row">
                                <div>
                                    <img v-if="imageUrl" :src="imgApi + imageUrl" class="avatar" />
                                    <img v-else src="@/assets/images/default.png" alt="" class="size">
                                </div>
                                <div class="flex-start">
                                    <div class="row">
                                        <el-button size="small" class="width">点击上传</el-button>
                                        <div>上传进度 {{ progress }}%</div>
                                    </div>
                                    <div slot="tip" class="el-upload__tip">
                                        建议尺寸750px * 560px 或4:3，JPG、PNG格式，图片小于5M
                                    </div>
                                </div>
                            </div>
                        </el-upload>
                    </div>
                </div>
                <div class="form-item column">
                    <div class="form-item-name">专栏详情</div>
                    <div class="form-item-content">
                        <div id="wang-editor"></div>
                    </div>
                </div>
            </template>

            <!-- 模块重复 -->
            <div class="form-title">商品信息</div>
            <div class="form-item column">
                <div class="form-item-name">售卖方式</div>
                <div class="column-pay">
                    <div class="form-item-content">
                        <el-radio v-model="form.sale" label="1" @change="showPay">付费</el-radio>
                        <el-radio v-model="form.sale" label="2" @change="hidePay">免费</el-radio>
                    </div>
                    <div v-if="isPay" class="sale">
                        <div class="sale-price">商品价格</div>
                        <el-input v-model="form.price" class="input-price" placeholder="请输入价格">
                            <template slot="append">元</template>
                        </el-input>
                    </div>
                </div>
            </div>
            <div class="form-title">上架设置</div>
            <div class="form-item">
                <div class="form-item-name">上架设置</div>
                <div class="form-item-content">
                    <el-radio v-model="form.put" label="2">立即上架</el-radio>
                    <el-radio v-model="form.put" label="1">暂不上架</el-radio>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import Core from '@/core';
import {baseUrl} from "@/core/api/config"

import axios from 'axios';
axios.defaults.headers.post['Content-Type'] = 'application/json';

import wangEditor from 'wangeditor';

export default {
    components: {
        ScreenRecordingWatermark: () => import('./components/ScreenRecordingWatermark.vue')
    },
    data() {
        return {
            uploadUrl: Core.Const.NET.FILE_UPLOAD_END_POINT,
            imgApi: Core.Const.NET.FILE_URL,
            video: Core.Const.VIDEO_ACCEPT,
            audio: Core.Const.AUDIO_ACCEPT,
            FILE_TYPE: Core.Const.UPLOAD_FILE_SUFFIX.IMAGE,
            type: '10',
            id: '',
            detail: {},
            form: {
                name: '',
                text_copy_limit: 2,
                open: false,
                editor: null,
                editorData: '',
                sale: '1',
                price: '',
                put: '0',
                cover: '',
                preview_time: {
                    time: 1
                },
                run: 1,
                info: '',
                editor1: null,
                editorData1: '',
                opacityConfig: {
                    copy_right: 1,
                    font_size: 26,
                    opacity: 40,
                    color: '#0F5DFB',
                }
            },
            isPay: true,
            imageUrl: '',
            fileList: [],
            tryIt: false,
            media: '',
            progress: 0,
            progressVideo: 0,
        };
    },
    computed: {},
    created() {
        console.log('created');
    },
    async mounted() {
        this.type = `${this.$route.query.type}`;
        this.id = this.$route.query.id;
        if (this.id) {
            await this.getDetail();
        } else {
            setTimeout(async () => {
                await this.initEditor();
            }, 50);
        }
        this.$forceUpdate();
    },
    methods: {
        initEditor() {
            let editor = new wangEditor(`#wang-editor`);
            // 配置 onchange 回调函数，将数据同步到 vue 中
            editor.config.showLinkImg = true;
            editor.config.uploadImgServer = this.uploadUrl;
            editor.config.uploadFileName = 'file';
            editor.config.uploadImgMaxSize = 50 * 1024 * 1024;
            editor.config.uploadImgHooks = {
                customInsert: (insertImgFn, result) => {
                    insertImgFn(
                        Core.Const.NET.FILE_URL_PREFIX2 + result.data.name
                    );
                }
            };
            editor.config.onchange = (newHtml) => {
                this.form.editorData = newHtml;
            };
            // 创建编辑器
            editor.create();
            this.form.editor = editor;

            const editorTry = new wangEditor(`#wang-editor1`);
            // 配置 onchange 回调函数，将数据同步到 vue 中
            editorTry.config.showLinkImg = true;
            editorTry.config.uploadImgServer = this.uploadUrl;
            editorTry.config.uploadFileName = 'file';
            editorTry.config.uploadImgMaxSize = 50 * 1024 * 1024;
            editorTry.config.uploadImgHooks = {
                customInsert: (insertImgFn, result) => {
                    insertImgFn(
                        Core.Const.NET.FILE_URL_PREFIX2 + result.data.name
                    );
                }
            };
            editorTry.config.onchange = (newHtml) => {
                this.form.editorData1 = newHtml;
            };
            // 创建编辑器
            editorTry.create();
            this.form.editor1 = editorTry;
        },
        async getDetail() {
            await Core.Api.Course.detail(this.id).then(async (res) => {
                this.imageUrl = res.detail.cover;
                this.media = res.detail.media;
                this.fileList.push({
                    name: res.detail.media,
                    url: res.detail.media
                });
                this.form.name = res.detail.name;
                let config = JSON.parse(res.detail.preview_config);
                this.form.preview_time.time = config.time;

                let opacityConfig = res.detail.prevent_copy_mask_config ? JSON.parse(res.detail.prevent_copy_mask_config) : '';
                this.form.opacityConfig = opacityConfig;
                if (res.detail.price == 0) {
                    this.form.sale = '2';
                    this.isPay = false;
                } else {
                    this.form.sale = '1';
                    this.form.price = res.detail.price / 100;
                }
                this.form.put = res.detail.status + '';
                if (res.detail.flag_enable_preview === 1) {
                    this.form.open = true;
                    this.tryIt = true;
                } else {
                    this.form.open = false;
                }
                if (res.detail.flag_show_prevent_copy_mask === 1) {
                    this.form.run = 1;
                } else {
                    this.form.run = 0;
                }
                this.form.text_copy_limit =
                    res.detail.flag_prevent_copy_text;
                await this.initEditor();
                this.form.editor.txt.html(res.detail.detail);
            });
            console.log('getDetail end');
        },
        handleCourseSubmit({copy_right, font_size, transparency, activeColor}) {
            this.form.opacityConfig = {
                copy_right: copy_right,
                font_size: font_size,
                opacity: transparency,
                color: activeColor,
            }

        },
        showPay() {
            this.isPay = true;
        },
        hidePay() {
            this.isPay = false;
            this.form.price = 0;
        },
        openTryIt() {
            this.tryIt = !this.tryIt;
        },
        uploadVideoFile(e) {
            const that = this;
            return new Promise(async (resolve, reject) => {
                let file = e.file;
                let uploadName = e.file.name;
                let {
                    host,
                    key,
                    access_id,
                    signature,
                    policy,
                    expire
                } = await this.getFormDataParams(e.file.type, uploadName);
                Core.Api.upload({
                    action: host,
                    data: {
                        key,
                        policy,
                        signature,
                        OSSAccessKeyId: access_id
                    },
                    name: 'file',
                    filename: uploadName,
                    file: file,
                    onProgress: function (e) {
                        console.log('[upload-clip-image] progress', e.percent);
                        that.progressVideo = e.percent.toFixed(2);
                    },
                    onSuccess: function (result) {
                        console.log('[upload-clip-image] success', result);
                        that.media = key;
                        // that.fileList.push({ name: uploadName, url: key });
                        // console.log('that.fileList', that.fileList)
                    },
                    onError: function (e) {
                        console.log('[upload-clip-image] error', e);
                    }
                });
            });
        },
        uploadFile(e) {
            let that = this;
            return new Promise(async (resolve, reject) => {
                let file = e.file;
                let {
                    host,
                    key,
                    access_id,
                    signature,
                    policy,
                    expire
                } = await this.getFormDataParams(e.file.type, e.file.name);
                Core.Api.upload({
                    action: host,
                    data: {
                        key,
                        policy,
                        signature,
                        OSSAccessKeyId: access_id
                    },
                    name: 'file',
                    filename: e.file.name,
                    file: file,
                    onProgress: function (e) {
                        console.log('[upload-clip-image] progress', e.percent);
                        that.progress = e.percent;
                    },
                    onSuccess: function (result) {
                        console.log('[upload-clip-image] success', result);
                        that.imageUrl = key;
                    },
                    onError: function (e) {
                        console.log('[upload-clip-image] error', e);
                        reject(e);
                    }
                });
            });
        },
        getFormDataParams(type, fileName) {
            return new Promise((resolve, reject) => {
                Core.Api.UploadFile.uploadFile(type, fileName).then((res) => {
                    resolve(res);
                });
            });
        },
        beforeAvatarUpload(file) {
            const isJPG =
                file.type === 'image/jpeg' || file.type === 'image/png';
            // const isLt5M = file.size / 1024 / 1024 < 5;

            if (!isJPG) {
                this.$message.error('上传图片只能是 JPG、PNG 格式!');
            }
            // if (!isLt5M) {
            //     this.$message.error('上传图片大小不能超过 5MB!');
            // }
            return isJPG;
        },

        handleExceed(files, fileList) {
            this.$message.warning('当前限制选择 1 个文件，请先移除原有文件');
        },
        handleRemove(file) {
            this.$refs.upload.clearFiles();
            this.fileList = [];
        },
        beforeRemove(file, fileList) {
            return this.$confirm(`确定移除 ${file.name}？`);
        },
        handleSave() {
            const config = {
                id: 0 || this.id,
                group_id: 0,
                name: this.form.name,
                media: this.media,
                media_type: this.type,
                cover: this.imageUrl,
                detail: this.form.editorData,
                price: this.form.price * 100 || 0,
                flag_prevent_copy_text: this.form.text_copy_limit,
                flag_enable_preview: Number(this.form.open),
                preview_config: JSON.stringify(this.form.preview_time),
                try_content: this.form.editorData1,
                status: this.form.put,
                prevent_copy_mask_config: JSON.stringify(this.form.opacityConfig),
                flag_show_prevent_copy_mask: this.form.run
            };

            if (this.form.sale === '1' && !config.price) {
                return this.$message.warning('请输入价格');
            }
            console.log('this.fileList', this.fileList);
            if (this.type === 20 && this.fileList.length < 1) {
                return this.$message.warning('请选择音频文件');
            }
            if (this.type === 30 && this.fileList.length < 1) {
                return this.$message.warning('请选择视频文件');
            }
            if (this.form.open && !this.form.preview_time) {
                return this.$message.warning('请输入试听时长');
            }
            if (!config.name) {
                return this.$message.warning('请输入名称');
            }
            const data = JSON.stringify(config);
            axios
                .post(
                    baseUrl +
                        '/provider/1/course/save' +
                        '?token=' +
                        Core.Data.getToken(),
                    data
                )
                .then((res) => {
                    console.log('handleSave =>', res);
                    this.$message.success('保存成功');
                    this.goBack();
                });
        },
        goBack() {
            this.$router.go(-1);
        }
    }
};
</script>
<style lang="scss" scoped>
.container {
    padding: 15px 30px;
    .head {
        font-size: 20px;
        font-weight: 500;
        color: #323538;
        @include flex(row, space-between);
        margin-bottom: 15px;
    }
    .form {
        width: 100%;
        background: #fff;
        box-sizing: border-box;
        padding: 30px 60px;
        border: 1px solid #e1e7ec;
        border-radius: 6px;
        .form-title {
            font-size: 16px;
            font-weight: 500;
            color: #323538;
            position: relative;
            margin-top: 40px;
            &:first-of-type {
                margin-top: 0;
            }
            &::before {
                content: '';
                width: 4px;
                height: 16px;
                background: #1D517E;
                border-radius: 2px;
                position: absolute;
                left: -10px;
                top: 1px;
            }
        }

        .form-item {
            width: 100%;
            margin-top: 30px;
            @include flex(row, flex-start);
            &.column {
                align-items: flex-start;
            }
            &.mt-0 {
                margin-top: 5px;
            }
            .watermark {
                display: flex;
                flex-direction: row;
                align-items: center;
                .open {
                    font-size: 12px;
                    cursor: pointer;
                }
            }
            .form-item-name {
                width: 10%;
                font-size: 14px;
                color: #323538;
                &.top-4 {
                    margin-top: 4px;
                }
            }
            .form-item-content {
                position: relative;
                #wang-editor {
                    position: relative;
                    z-index: 1;
                }
                #wang-editor1 {
                    position: relative;
                    z-index: 1;
                }
                .upload-demo {
                    .row-progress {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                    }
                    .row {
                        display: flex;
                        flex-direction: row;
                        align-items: flex-start;
                        .flex-start {
                            display: flex;
                            flex-direction: column;

                            .row {
                                display: flex;
                                flex-direction: row;
                                align-items: center;
                                .width {
                                    width: 86px;
                                    margin-right: 20px;
                                }
                            }
                        }
                        .size {
                            width: 200px;
                            height: 112px;
                            margin-right: 20px;
                        }
                    }
                }
                #wang-editor {
                    max-width: 1014px;
                }
                #wang-editor1 {
                    max-width: 1014px;
                }
                &.box-60 {
                    width: 70%;
                }
                &.column {
                    @include flex(column, flex-start, flex-start);
                }
                .row {
                    @include flex(row, flex-start);
                }
                .tip {
                    font-size: 12px;
                    color: #999999;
                    padding-left: 24px;
                }
                .tips {
                    font-size: 12px;
                    color: #999999;
                    margin: 12px 0;
                    padding-left: 24px;
                }
                .listen {
                    display: flex;
                    flex-direction: row;
                    width: 775px;
                    height: 90px;
                    align-items: center;
                    background: #f7f9fa;
                    margin-top: 10px;
                    border-radius: 2px;
                    .listen-set {
                        font-size: 14px;
                        padding-left: 20px;
                        padding-right: 54px;
                    }
                    .listen-area {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        .front {
                            width: 42px;
                            padding-right: 10px;
                        }
                        .time {
                            width: 120px;
                        }
                        .minute {
                            width: 98px;
                            padding-left: 10px;
                            padding-right: 223px;
                        }
                    }
                }
            }
            .column-pay {
                display: flex;
                flex-direction: column;
            }
        }
        .sale {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-top: 20px;
            .sale-price {
                font-size: 14px;
                font-weight: 400;
                color: #323538;
                padding-right: 20px;
            }
            .input-price {
                width: 198px;
            }
        }
    }
}
</style>
